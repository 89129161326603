export const history=[
    {
        "job_id":"#1",
        "status":"success",
        "created_at":"11/05/2023"
    },
    {
        "job_id":"#234",
        "status":"success",
        "created_at":"11/05/2023"
    },
    {
        "job_id":"#456",
        "status":"success",
        "created_at":"11/05/2023"
    },
    {
        "job_id":"#13",
        "status":"failed",
        "created_at":"11/05/2023"
    },
    {
        "job_id":"#1094",
        "status":"success",
        "created_at":"11/05/2023"
    },
    {
        "job_id":"#19321",
        "status":"success",
        "created_at":"11/05/2023"
    },
    {
        "job_id":"#14950",
        "status":"success",
        "created_at":"11/05/2023"
    },
    {
        "job_id":"#193401",
        "status":"success",
        "created_at":"11/05/2023"
    },
    {
        "job_id":"#42",
        "status":"success",
        "created_at":"11/05/2023"
    }
]